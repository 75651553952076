import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpContextToken,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { MsalInterceptor } from '@azure/msal-angular';

//@Injectable()
// export class AuthInterceptor implements HttpInterceptor {
//   constructor(private router: Router) {}

//   intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
//     // Retrieve the token from your authentication service or local storage
//     const authToken = localStorage.getItem('Access-Token-Azure-B2C'); // Replace with actual token retrieval logic

//     // Clone the request and add the Authorization header
//     const authReq = req.clone({
//       setHeaders: {
//         Authorization: `Bearer ${authToken}`
//       }
//     });

//     return next.handle(authReq).pipe(
//       catchError((error: HttpErrorResponse) => {
//         if (error.status === 403) {
//           this.router.navigate(['/unauthorized']);
//         }
//         return throwError(error);
//       })
//     );
//   }
// }
// export class AuthInterceptor implements HttpInterceptor {
//   constructor(private router: Router) {}

//   intercept(req: HttpRequest<any>, next: HttpHandler): Observable<any> {
//     return next.handle(req).pipe(
//       catchError((error: HttpErrorResponse) => {
//         if (error.status === 403) {
//           this.router.navigate(['/unauthorized']);
//         }
//         return throwError(error);
//       })
//     );
//   }
// }

export const BYPASS_MSAL = new HttpContextToken(() => false);

@Injectable()
export class AppHttpInterceptor
  extends MsalInterceptor
  implements HttpInterceptor
{
  override intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    if (request.context.get(BYPASS_MSAL)) {
      return next.handle(request);
    }
    return super.intercept(request, next);
  }
}
